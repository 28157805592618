import React, { Component, Fragment } from 'react';
import { FORM_ERROR } from 'final-form';
import { exposeMetrics } from 'react-metrics';
import { Form } from 'react-final-form';
import { PrimaryButton } from 'components/Shared/Button/Button';
import FormField from 'components/Shared/Form/FormField/FormField';
import { sendHubSpotFormSubmission } from 'components/Shared/FormHandlers';
import Validators, { composeValidators } from 'components/Shared/Form/validators';
import { getHasFullAccess } from 'components/IpCheckContainer/IpCheckContainer';
import { getZip } from 'components/ZipGateContainer/ZipGateContainer';
import { FIELD_TYPES } from '../Shared/Form/FormField/FormField';
import IconExclamation from 'static/images/icons/exclamation-circle.inline.svg';
import './EmailForm.scss';


class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.decorators = [];
    this.validators = {
      email: composeValidators(
        Validators.required(),
        Validators.email()
      )
    };
    this.state = {
      submittedName: null
    }
    this.form = null;
  }

  reset = () => {
    this.form.form.reset();
    this.form.form.resetFieldState('email');
  }

  submit = async values => {
    try {
      const { zip } = getZip();
      const service_available = getHasFullAccess() ? 'true' : 'false';
      await sendHubSpotFormSubmission({ ...values, zip, service_available }, this.props.formType);
      this.props.metrics.track('email_popup_form_submit', {});
      this.props.onSuccess()
      return {};
    } catch (e) {
      window.Sentry.captureException(e);
      this.props.metrics.track('email_popup_form_submit_error');
      return { [FORM_ERROR]: this.props.data.submissionError || 'Form submitting failed' };
    }
  };

  render() {
    const { data } = this.props;
    const validators = this.validators;
    return (
      <div className={`email-form ${this.props.className || ''}`}>
        <Form
          decorators={this.decorators}
          onSubmit={this.submit}
          ref={(form) => { this.form = form; }}
          render={({ handleSubmit, submitError, submitting }) => (
            <Fragment>
              <form 
                className="email-form__container"
                onSubmit={event => {
                  handleSubmit(event).then( (res) => { 
                    if(!res[FORM_ERROR]) {
                      this.reset();
                    }
                  });
                }}
              >
                <FormField 
                  data={{
                    name: 'email',
                    placeholder: 'Enter your email address',
                    required: true,
                    type: FIELD_TYPES.email
                  }}
                  validate={validators.email}
                />
                <PrimaryButton
                  className="email-form__submit-button"
                  disabled={submitting}
                  type="submit"
                >
                  {data.submitButton}
                </PrimaryButton>
              </form>
              { submitError && (
                <div className="email-form__submit-error">
                  <div className="icon-box"><IconExclamation className="icon"/></div>
                  <div dangerouslySetInnerHTML={{ __html: submitError }} />
                </div>
              )}
            </Fragment>
          )}
        />
      </div>
    );
  }
}

export default exposeMetrics(EmailForm);
