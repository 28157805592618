import React from 'react';


const CTAPopUpContext = React.createContext({
  showPopUp: false,
  showPopUpButton: false,
  togglePopUp: () => { },
  togglePopUpButton: () => { },
});

export default CTAPopUpContext;