import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const MicrositeFooterLinks = ({data}) => {
  return data.map((footerLink, i) => <a href={footerLink.url} key={i}>{footerLink.name}</a>);
};

const MicrositeFooter = ({ data }) => {
  return (
    <footer>
      <div className="A3GradientDivider"></div>
      <div className="A3ContentBox">
        <div className="FooterLinks">
          <MicrositeFooterLinks data={data.blocksYaml.MicrositeFooterLinks.links}></MicrositeFooterLinks>
        </div>
        <p className="FooterAddress">
        <div
            dangerouslySetInnerHTML={{
              __html: data.blocksYaml.MicrositeFooterAddress,
            }}
          />
        </p>
      </div>
    </footer>
  );
};

const MicrositeFooterFaq = props => (
  <StaticQuery
    query={graphql`
      query WelcomekitQuery {
        blocksYaml(MicrositeFooterAddress: {}) {
          MicrositeFooterAddress
          MicrositeFooterLinks {
            links {
              name
              url
            }
          }
        }
      }
    `}
    render={data => <MicrositeFooter data={data} {...props} />}
  />
);

export default MicrositeFooterFaq;
