import React from 'react';
import Dropdown from 'react-dropdown';
import './FormInput.scss';


const BaseInput = ({ children, className, data, error, message, required }) => {
  return (
    <label className={`${className} ${error ? 'error' : ''} ${required ? 'required' : ''}`}>
      {data.icon ? (
        <img className='aaa-input-icon' src={data.icon.file.url} />
      ) : null}
      {data.label && <span className='aaa-input-label'>
        <span>{data.label}</span>
      </span>}
      {children}
      {message && <span className={error ? "aaa-input-error" : "aaa-input-message"} dangerouslySetInnerHTML={{ __html: message}} />}
    </label>
  );
}

export const CheckBoxInput = ({ data, input, type, ...rest }) => (
  <BaseInput className={`aaa-checkbox-input ${input.value == true ? 'checked' : ''}`} data={data} {...rest}>
    <input type={type} {...input} />
  </BaseInput>
);

export const RadioInput = ({ className, data, input, type, error, message, required }) => (
  <div className={`aaa-radio-group ${error ? 'error' : ''} ${required ? 'required' : ''} ${className ? className : ''}`}>
    <div className={"aaa-input-label"}>
      {data.label}
    </div>
    <ul>
      { data.options.map((option, i) => (
        <li key={`${data.name}_${i}`}>
          <label className={`aaa-radio-input ${option.value === input.value ? 'checked' : ''}`}>
            <span className='aaa-radio-input__label'>
              <span>{option.label}</span>
            </span>
            <input
              {...input}
              type={type}
              value={option.value}
              onChange={() => input.onChange(option.value)}
            />
          </label>
        </li>
      ))}
    </ul>
    {message && <span className={error ? "aaa-input-error" : "aaa-input-message"} dangerouslySetInnerHTML={{ __html: message}} />}
  </div>
);

// eslint-disable-next-line no-unused-vars
export const SelectInput = ({ data, input, type, ...rest }) => (
  <BaseInput className={'aaa-select-input'} data={data} {...rest}>
    <Dropdown
      arrowClassName='aaa-select-input__arrow'
      controlClassName='aaa-select-input__control'
      menuClassName='aaa-select-input__menu'
      placeholder={data.placeholder ? data.placeholder : ''}
      placeholderClassName='aaa-select-input__placeholder'
      options={data.options}
      {...input}
      onChange={(data) => input.onChange(data.value)}
    />
  </BaseInput>
);

// eslint-disable-next-line no-unused-vars
export const TextArea = ({ data, input, type, rows, ...rest }) => (
  <BaseInput className={'aaa-text-area'} data={data} {...rest}>
    <textarea
      placeholder={data.placeholder ? data.placeholder : ''}
      rows={rows ? rows : '5'}
      {...input}
    />
  </BaseInput>
);

export const TextInput = ({ data, input, type, ...rest }) => (
  <BaseInput className={'aaa-text-input'} data={data} {...rest}>
    <input
      placeholder={data.placeholder ? data.placeholder : ''}
      type={type}
      {...input}
    />
  </BaseInput>
);
