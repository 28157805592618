import React, { Component, Fragment } from 'react';
import { exposeMetrics, PropTypes as MetricsPropTypes } from 'react-metrics';
import {isMobile} from 'react-device-detect';
import IdleTimer from 'react-idle-timer'
import OutsideClickHandler from 'react-outside-click-handler';
import Cookies from 'js-cookie';
import { Modal } from 'components/ModalWindow/ModalWindow';
// TODO: Replace BlackFridayPopUp with RegularPopUp after 12/15
//import BlackFridayPopUp from './BlackFridayPopUp';
import RegularPopUp from './RegularPopUp';
import ZipContext from 'components/Shared/ZipContext';
import './GatherEmailPopUp.scss';
import {
  IDLE_TIMEOUT,
  SH_COOKIE_EMAIL_ASKED
} from 'components/Shared/Constants';


export class GatherEmailPopUp extends Component {

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.initialPosition = 0;
    this.state = {
      showPopup: false,
      usePopUp: Cookies.get(SH_COOKIE_EMAIL_ASKED) == null,
      submitSuccessed: false,
    }
  }

  componentDidMount() {
    this.initPopUp();
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.zipContext.zip) {
      this.initPopUp();
    }
  }

  componentWillUnmount() {
    if(this.state.usePopUp) {
      this.removeEventListeners();
    }
  }

  initPopUp = () => {
    if(this.state.usePopUp && this.props.zipContext.zip) {
      if(isMobile) {
        window.addEventListener('scroll', this.handleScroll);
      } else {
        document.addEventListener("mouseleave", this.handleMouseLeave);
      }
    }
  }

  handleScroll = () => {
    const position = document.documentElement.scrollTop;
    if(this.initialPosition < position) {
      this.initialPosition = position;
      if((position + window.innerHeight)/document.documentElement.scrollHeight > 0.5) {
        this.togglePopUp();
      }
    } else if(this.initialPosition - position > window.innerHeight) {
      this.togglePopUp();
    }
  }

  handleMouseLeave = e => {
    if(e.clientY < 1) {
      this.togglePopUp();
    }
  }

  removeEventListeners = () => {
    if(isMobile) {
      window.removeEventListener('scroll', this.handleScroll);
    } else {
      document.removeEventListener('mouseleave', this.handleMouseLeave);
    }
  }

  setCookie = () => {
    Cookies.set(SH_COOKIE_EMAIL_ASKED, 1, {expires: 365});
  }

  togglePopUp = () => {
    this.removeEventListeners();
    this.setCookie();
    if(this.state.showPopup && !this.state.submitSuccessed) {
      this.props.metrics.track('email_popup_form_close', {});
    }
    this.setState({ 
      showPopup: !this.state.showPopup, 
      usePopUp: false 
    });
  }
  
  onSuccess = () => {
    this.setState({
      submitSuccessed: true
    });
  }

  render() {
    return (
      <Fragment>
        { "undefined" != typeof document && this.state.usePopUp && this.props.zipContext.zip &&
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={() => this.togglePopUp()}
            debounce={250}
            timeout={IDLE_TIMEOUT} 
          />
        } 
        { this.state.showPopup &&
          <Modal>
            <div className="aaa-gather-email-overlay" />
            <OutsideClickHandler onOutsideClick={() => this.togglePopUp()}>
              <RegularPopUp
                isSubmitted={this.state.submitSuccessed}
                onSuccess={this.onSuccess}
                togglePopUp={this.togglePopUp}
              />
            </OutsideClickHandler>
          </Modal>
        }
      </Fragment>
    );
  }
}

class CombinedContext extends Component {
  constructor() {
    super();
  }

  static contextTypes = {
    metrics: MetricsPropTypes.metrics,
  };

  render() {
    return (
      <ZipContext.Consumer>
        {context1 => <GatherEmailPopUp {...this.props} zipContext={context1} />}
      </ZipContext.Consumer>
    );
  }
}

export default exposeMetrics(CombinedContext);
