import {
  API_BASE_URL,
  API_IP_CHECK_PATH,
} from 'components/Shared/Constants';
import Api from 'components/Shared/Api';

class IPApiService {
  constructor(baseUrl) {
    this.api = new Api(baseUrl);
  }

  getIpInfo = async () => {
    return await this.api.get(API_IP_CHECK_PATH, {});
  };

  getZip = async () => {
    try {
      const ipInfo = await this.getIpInfo();
      return ipInfo.services.geo.ip.postalCode;
    } catch (err) {
      if (err.status === 500) {
        return null;
      }
      throw err;
    }
  }

}
  
const IPApi = new IPApiService(API_BASE_URL);
  
export default IPApi;
