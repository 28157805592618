import React from 'react';
import classnames from 'classnames';
import './FormRow.scss';

const FormRow = ({ children, className, ...props }) => (
  <div
    className={classnames("aaa-form-row", className)}
    { ... props }
  >
    {children}
  </div>
);

export default FormRow;