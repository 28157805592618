import {
  API_BASE_URL,
  API_ZIP_CHECK_PATH,
} from 'components/Shared/Constants';
import Api from 'components/Shared/Api';

class ZipApiService {
  constructor(baseUrl) {
    this.api = new Api(baseUrl);
  }

  getZipInfo = async (zip) => {
    return await this.api.post(API_ZIP_CHECK_PATH, {
      zip
    });
  };

}
  
const ZipApi = new ZipApiService(API_BASE_URL);
  
export default ZipApi;
