import axios from 'axios';
import qs from 'querystring';
import Cookies from 'js-cookie';

import { updateAccount } from './CCPA';
import {
  API_BASE_URL,
  API_FORM_HANDLER_GCF_URL,
  API_VALIDATE_PROMO_CODE_PATH,
  API_HUBSPOT_PATH,
} from './Constants';

const apiProxyURL = `${API_BASE_URL}${API_FORM_HANDLER_GCF_URL}`;
const apiHubspotProxyURL=`${API_BASE_URL}${API_HUBSPOT_PATH}`;
const validateCodeURL = `${API_BASE_URL}${API_VALIDATE_PROMO_CODE_PATH}`;

const HS_COOKIE_HUTK = 'hubspotutk';
const HS_HUTK = 'hutk';

export const HS_FORM_TYPES = {
  BLACK_FRIDAY: 'black_friday',
  CTA: 'cta',
  EMAIL: 'gatheremail',
  SAMSUNG: 'samsung'
};

export const sendFreeQuoteRequest = async (data) => {
  updateAccount(data.email);
  const response = await axios.post(apiProxyURL,
    qs.stringify({
      type: 'free_quote',
      name: data.name,
      phone: data.phone,
      email: data.email
    }),
    {  
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
  return response;
}

export const sendEmailUsRequest = async (data) => {
  updateAccount(data.email);
  const response = await axios.post(apiProxyURL, 
    {
      type: 'email_us',
      ...data
    },
    {
      headers: {
        'Content-type': 'application/json'
      }
    }
  );
  return response;
}

export const sendHubSpotFormSubmission = async (data, formType) => {
  const hsContext = {
    pageUri: window.location.href,
    pageName: document.title
  }
  const hutk = Cookies.get(HS_COOKIE_HUTK);
  if(hutk) {
    hsContext[HS_HUTK] = hutk;
  }
  const body = {
    userData: data,
    hsContext,
    formType,
  }
  const response = await axios.post(apiHubspotProxyURL,
    body,
    {
      headers: {
        'Content-type': 'application/json'
      }
    }
  );
  return response;
}

export const validatePromoCode = value => new Promise (resolve => {
  if(value === undefined) {
    resolve(undefined);
  }
  fetch(validateCodeURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
       Accept: 'application/json',
    },
    body: JSON.stringify({ code: value })
  }).then( 
    res => {
      if(res.status == 200) {
        res.json().then(data => data.isValid ? resolve(undefined) : resolve(false) );
      } else {
        resolve(false);
      }
    }
  ).catch(
    () => resolve('Unable to check code')
  );
});
