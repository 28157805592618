import React from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import { withRegularPopUpData } from './RegularPopUpHOC';
import CloseIcon from 'static/images/icons/icon-close.inline.svg';
import EmailForm from './EmailForm';
import { PrimaryButton, SecondaryButton } from 'components/Shared/Button/Button';
import { HS_FORM_TYPES } from 'components/Shared/FormHandlers';
import './RegularPopUp.scss';


export const RegularPopUp = ({ data, isSubmitted, onSuccess, togglePopUp }) => (
  <div className={classnames("aaa-gather-email", {submitted: isSubmitted})}>
    <div className="aaa-gather-email__image-box">
      <Img
        className="aaa-gather-email__image"
        fluid={data.image.childImageSharp.fluid}
      />
    </div>
    <div className="aaa-gather-email__form-box">
      <div 
        className="aaa-gather-email__description"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
      <EmailForm 
        data={data}
        formType={HS_FORM_TYPES.EMAIL}
        onSuccess={onSuccess}
      />
      <SecondaryButton
        className="aaa-gather-email__decline"
        onClick={ () => togglePopUp() }
      >
        {data.declineButton}
      </SecondaryButton>
      <div className="aaa-gather-email__thank-you">
        <div
          className="aaa-gather-email__thank-you-text"
          dangerouslySetInnerHTML={{__html: data.success.description}}
        />
        <PrimaryButton
          className="aaa-gather-email__back-to-site-button"
          onClick={() => togglePopUp()}
        >
          {data.success.closeButton}
        </PrimaryButton>
      </div>
    </div>
    <div 
      className="aaa-gather-email__disclaimer" 
      dangerouslySetInnerHTML={{__html: data.disclaimer}}
    />
    <div className="aaa-gather-email__close">
      <CloseIcon 
        className='aaa-gather-email__close-icon'
        onClick={ () => togglePopUp() } 
      />
    </div>
  </div>
);

export default withRegularPopUpData(RegularPopUp);
