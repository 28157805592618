import React, { Component } from 'react';
import { exposeMetrics } from 'react-metrics';
import { Form } from 'react-final-form';
import { PrimaryButton } from 'components/Shared/Button/Button';
import Cookies from 'js-cookie';
import FormField from 'components/Shared/Form/FormField/FormField';
import FormRow from 'components/Shared/Form/FormRow/FormRow';
import ZipApi from 'components/Shared/ZipApi';
import format from 'string-template';
import queryString from 'query-string';
import {
  COOKIES_DOMAIN,
  COOKIES_ZIP_KEY,
  CLUB_ARIZONA,
} from 'components/Shared/Constants';
import Validators, {
  composeValidators,
  prepareErrors,
} from 'components/Shared/Form/validators';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import ZipContext from 'components/Shared/ZipContext';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import { setHasFullAccess } from 'components/IpCheckContainer/IpCheckContainer';
import { withZipGateData } from './ZipGateDataHOC';
import './ZipGateContainer.scss';

export const getZip = () => {
  const zipCookie = Cookies.get(COOKIES_ZIP_KEY);
  if (!zipCookie) {
    return null;
  }
  const values = zipCookie.split('|');
  if (values.length !== 4) {
    return null;
  }
  const [zip, app, club, device] = values;
  return { zip: parseInt(zip), app, club: parseInt(club), device };
};

export const setZip = ({ zip, club }) => {
  let device;
  if (window.innerWidth < 768) {
    device = 'SP';
  } else if (window.innerWidth < 992) {
    device = 'TB';
  } else {
    device = 'PC';
  }
  const options = {
    expires: 365,
  };
  if (COOKIES_DOMAIN) {
    options.domain = COOKIES_DOMAIN;
  }
  Cookies.set(COOKIES_ZIP_KEY, `${zip}|AAA|${club}|${device}`, options);
};

export const isArizona = () => {
  const zipInfo = getZip();
  if (!zipInfo) {
    return false;
  }
  const { club } = zipInfo;
  return club === CLUB_ARIZONA;
};

export class ZipForm extends Component {
  constructor(props) {
    super(props);
    this.decorators = [];
    this.validators = [];
    this.props.data.zipForm.formFields.forEach(field => {
      const validators = [];
      const messages = prepareErrors(field.fieldErrors);
      field.validators.forEach(validator => {
        if (validator in Validators) {
          validators.push(Validators[validator](messages[validator]));
        }
      });
      if (field.required) {
        validators.push(Validators.required(messages));
      }
      this.validators[field.name] = composeValidators(...validators);
    });
  }

  submit = async values => {
    const errorMessages = {
      invalidZip: 'Invalid zip code',
      genericError: 'Unable to validate zip',
      ...this.props.data.zipForm.formErrors.reduce((obj, err) => {
        obj[err.key] = format(err.value, { zip: values.zip });
        return obj;
      }, {}),
    };
    try {
      const zipInfo = await ZipApi.getZipInfo(values.zip);
      this.props.callback({
        zip: parseInt(values.zip),
        club: parseInt(zipInfo.services.geo.postal.clubs.club[0].code),
      });
    } catch (err) {
      window.Sentry.captureException(err);
      if (err.status === 500) {
        return {
          zip: errorMessages.invalidZip,
        };
      }
      return {
        zip: errorMessages.genericError,
      };
    }
  };

  render() {
    const { data } = this.props;
    return (
      <div className="aaa-zip-form">
        <div className="aaa-zip-form__dimmer">
          <div className="aaa-zip-form__container">
            <div className="row">
              <div className="aaa-zip-form__logo-container col-12">
                <img
                  alt="aaa-logo"
                  className="aaa-zip-form__logo"
                  src={data.logo.publicURL}
                />
              </div>
            </div>
            <div className="row">
              <div className="aaa-zip-form__title col-12">{data.title}</div>
            </div>
            <hr />
            <div className="aaa-zip-form__zip-prompt">{data.subtitle}</div>
            <Form
              onSubmit={this.submit}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  {data.zipForm.formFields.map(field => (
                    <FormRow key={field.name} className="row no-gutters">
                      <div className="col-12 col-md-8">
                        <FormField
                          data={field}
                          validate={this.validators[field.name]}
                        />
                      </div>
                      <div className="col-12 col-md-4">
                        <PrimaryButton
                          className="aaa-zip-form__button"
                          disabled={submitting ? 'disabled' : ''}
                          type="submit"
                        >
                          {data.zipForm.submitTitle}
                        </PrimaryButton>
                      </div>
                    </FormRow>
                  ))}
                </form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const RedirectModal = ({ logo, message, onClose, timeout }) => (
  <ModalWindow
    open
    timeout={timeout}
    onClose={onClose}
    render={() => {
      return (
        <div>
          <div className="row">
            <div className="aaa-zip-form__logo-container col-12">
              <img alt="aaa-logo" className="aaa-zip-form__logo" src={logo} />
            </div>
          </div>
          <div className="row">
            <div className="aaa-zip-form__zip-prompt col-12" 
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
        </div>
      );
    }}
  >
    {() => {
      return null;
    }}
  </ModalWindow>
);

export class ZipGateContainer extends Component {
  constructor() {
    super();
    this.state = {
      mounted: false,
      showZipGate: false,
      shouldRedirect: false,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    if (parsed.club && parsed.zip) {
      // todo: check zip and club?
      this.handleZip({
        zip: parseInt(parsed.zip),
        club: parseInt(parsed.club),
        source: 'url',
      });
      return;
    }
    const storedZipInfo = getZip();
    if (!storedZipInfo) {
      this.setState({ mounted: true, showZipGate: true });
      return;
    }
    const { zip, club } = storedZipInfo;
    this.handleZip({ zip, club, source: 'cookie' });
  }

  handleZip = ({ zip, club, source }) => {
    const { data } = this.props;
    setZip({ zip, club });
    if (data.allowedClubs.clubs.indexOf(club) !== -1) {
      if (data.mwg) {
        const fullAccess = data.allowedZips.zips.indexOf(zip) !== -1;
        setHasFullAccess(fullAccess);
        this.props.zipContext.setZip({ zip, club, fullAccess });
        this.setState({ mounted: true, showZipGate: false });
        this.props.metrics.track('in_market', {
          zip_code: zip,
          label: source,
          nonInteraction: 1,
        });
      } else {
        setHasFullAccess(false);
        this.props.zipContext.setZip({ zip, club, fullAccess: false });
        this.setState({ mounted: true, shouldRedirect: true });
      }
    } else {
      this.props.zipContext.setZip({ zip, club, fullAccess: false });
      if (!data.mwg) {
        this.setState({ mounted: true, showZipGate: false });
        this.props.metrics.track('in_market', {
          zip_code: zip,
          label: source,
          nonInteraction: 1,
        });
      } else {
        this.setState({ mounted: true, shouldRedirect: true });
      }
    }
  };

  performRedirect = () => {
    const { data } = this.props;
    const { zip, club } = getZip();
    const url = `${data.redirectURL}?${queryString.stringify({ zip, club })}`;
    window.location.replace(url);
  };

  render() {
    const { data, location } = this.props;

    if (!this.state.mounted) {
      return null;
    }

    if (this.state.shouldRedirect) {
      return (
        <RedirectModal
          timeout={5000}
          onClose={this.performRedirect}
          logo={data.logo.publicURL}
          message={data.redirectMessage}
        />
      );
    }

    if (!this.state.showZipGate) {
      return null;
    }

    return (
      <ZipForm
        data={data}
        callback={({ zip, club }) => {
          this.handleZip({ zip, club, source: 'zipgate_form' });
        }}
        location={location}
      />
    );
  }
}

class CombinedContext extends Component {
  constructor() {
    super();
  }

  static contextTypes = {
    metrics: MetricsPropTypes.metrics,
  };

  render() {
    return (
      <ZipContext.Consumer>
        {context1 => <ZipGateContainer {...this.props} zipContext={context1} />}
      </ZipContext.Consumer>
    );
  }
}

export default withZipGateData(exposeMetrics(CombinedContext));
