import React from 'react';
import { Field } from 'react-final-form';
import MailCheck from 'react-mailcheck';
import { 
  CheckBoxInput,
  RadioInput,
  SelectInput,
  TextArea,
  TextInput
} from 'components/Shared/Form/FormInput/FormInput';
import {  } from '../FormInput/FormInput';


export const CheckBoxField = ({ data, validate, ...props }) => (
  <Field name={data.name} type={data.type} validate={validate} {...props}>
    {({ input, meta }) => (
      <CheckBoxInput
        data={data}
        error={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting))
        }
        input={input}
        message={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting)) &&
          (meta.error || meta.submitError)
        }
        required={data.required}
        type={data.type}
      />
    )}
  </Field>
);

export const EmailField = ({ data, validate, ...props }) => (
  <Field name={data.name} validate={validate} {...props}>
    {({ input, meta }) => (
      <MailCheck email={input.value}>
        {suggestion => (
          <TextInput
            data={data}
            error={meta.touched && meta.error}
            input={input}
            message={
              meta.touched && (meta.error || suggestion)
                ? meta.error || `Did you mean "${suggestion.full}"?`
                : ''
            }
            required={data.required}
            type="email"
          />
        )}
      </MailCheck>
    )}
  </Field>
);

export const GenericTextField = ({ data, validate, ...props }) => (
  <Field name={data.name} validate={validate} {...props}>
    {({ input, meta }) => (
      <TextInput
        data={data}
        error={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting))
        }
        input={input}
        message={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting)) &&
          (meta.error || meta.submitError)
        }
        required={data.required}
        type={data.type}
      />
    )}
  </Field>
);

// eslint-disable-next-line no-unused-vars
export const RadioField = ({ className, data, validate, ...rest }) => (
  <Field name={data.name} validate={validate} {...rest}>
    {({ input, meta }) => (
      <RadioInput
        className={className}
        data={data}
        error={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting))
        }
        input={input}
        message={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting)) &&
          (meta.error || meta.submitError)
        }
        required={data.required}
        type={data.type}
      />
    )}
  </Field>
);

export const SelectField = ({ data, validate, ...props }) => (
  <Field name={data.name} validate={validate} {...props}>
    {({ input, meta }) => (
      <SelectInput
        data={data}
        error={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting))
        }
        input={input}
        message={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting)) &&
          (meta.error || meta.submitError)
        }
        required={data.required}
      />
    )}
  </Field>
);

export const TextAreaField = ({ data, validate, ...props }) => (
  <Field name={data.name} validate={validate} {...props}>
    {({ input, meta }) => (
      <TextArea
        data={data}
        error={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting))
        }
        input={input}
        message={
          meta.touched &&
          (meta.error ||
            (meta.submitError &&
              !meta.dirtySinceLastSubmit &&
              !meta.submitting)) &&
          (meta.error || meta.submitError)
        }
        required={data.required}
      />
    )}
  </Field>
);

export const ZIP_LENGTH = 5;

export const ZipField = ({ ...props }) => (
  <GenericTextField
    {...props}
    type="tel"
    parse={value => {
      if (!value) {
        return value;
      }
      const digits = value.replace(/[^\d]/g, '');
      return digits.slice(0, ZIP_LENGTH);
    }}
  />
)

const parseNumber = value => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, "");
}

const normalizePhone = value => {
  if(!value) {
    return value;
  }
  const phone = '+1' + parseNumber(value);
  return phone.length > 12 ? phone.slice(0,12) : phone;
}

const formatPhone = value => {
  if (!value) {
    return '';
  }
  value = value.slice(2);
  if (value.length <= 3) {
    return value;
  }
  if (value.length <= 7) {
    return `(${value.slice(0, 3)}) ${value.slice(3, 7)}`;
  }
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
}

export const PhoneField = ({ ...props }) => (
  <GenericTextField
    {...props}
    type="tel"
    format={formatPhone}
    parse={normalizePhone}
  />
)

export const FIELD_TYPES = {
  checkbox: 'checkbox',
  email: 'email',
  radio: 'radio',
  select: 'select',
  tel: 'tel',
  text: 'text',
  textarea: 'textarea',
  zip: 'zip',
  phone: 'phone',
  password: 'password',
};


const FormField = ({ data, ...props }) => {
  switch (data.type) {
    case FIELD_TYPES.checkbox:
      return <CheckBoxField data={data} {...props} />;
    case FIELD_TYPES.email:
      return <EmailField data={data} {...props} />;
    case FIELD_TYPES.radio:
      return <RadioField data={data} {...props} />
    case FIELD_TYPES.zip:
      return <ZipField data={data} {...props} />;
    case FIELD_TYPES.phone:
      return <PhoneField data={data} {...props} />;
    case FIELD_TYPES.textarea:
      return <TextAreaField data={data} {...props} />;
    case FIELD_TYPES.select:
      return <SelectField data={data} {...props} />;
    default:
      return <GenericTextField data={data} {...props} />;
  }
};

export default FormField;
