import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const withRegularPopUpData = (WrappedComponent) => {
  return class CTAFormDataHOC extends React.Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              gatherEmailYaml {
                declineButton
                description
                disclaimer
                image {
                  childImageSharp {
                    fluid(maxWidth: 518) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                submitButton
                submissionError
                success {
                  description
                  closeButton
                }
              }
            }
          `}
          render={data => <WrappedComponent data={data.gatherEmailYaml} {...this.props} />}
        />
      );
    }
  }
}
