import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const withZipGateData = WrappedComponent => {
  return class ZipGateDataHOC extends React.Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              zipGateYaml {
                id
                logo {
                  publicURL
                }
                title
                subtitle
                zipForm {
                  submitTitle
                  formFields {
                    name
                    type
                    placeholder
                    required
                    validators
                    fieldErrors {
                      key
                      value
                    }
                  }
                  formErrors {
                    key
                    value
                  }
                }
                allowedZips {
                  zips
                }
                allowedClubs {
                  clubs
                }
                mwg
                redirectURL
                redirectMessage
              }
            }
          `}
          render={data => (
            <WrappedComponent data={data.zipGateYaml} {...this.props} />
          )}
        />
      );
    }
  };
};
