import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemState,
} from 'react-accessible-accordion';
import AnimateHeight from 'react-animate-height';
import { MetricsElement } from 'react-metrics';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { MainNavLink } from 'components/Shared/Link/Link';
import { FullWidthPopUp, PopUp } from './PopUpMenu';
import IconArrowDown from 'static/images/icons/chevron-down.inline.svg';
import UserIcon from 'static/images/icons/user.inline.svg';
import './Navigation.scss';


const withSubmenu = ( WrappedComponent, className, name ) => {

  return class HeaderSubmenuHOC extends Component {

    constructor(props) {
      super(props);
      this.state = {
        showSubmenu: false
      }
    }
    
    toggleSubmenu = () => {
      this.setState((state) => {
        return {
          showSubmenu: !state.showSubmenu
        }
      });
    }
   
    render() {
      return (
        <div
          className={className}
          onMouseEnter={ () => this.toggleSubmenu() }
          onMouseLeave={ () => this.toggleSubmenu() }
        >
          <WrappedComponent {...this.props } />
          <span className='caret' />
          <PopUp items={this.props.items} name={name} expanded={this.state.showSubmenu} />
        </div>
      );
    }
  }
};

export const CallUsNavigation = ({ items }) => (
  <ul className='aaa-callus-menu'>
    {items.map( (item, i) => (
      <li className='aaa-callus-menu__item' key={`callus_${i}`}>
        <span className='aaa-callus-menu__label'>{item.label}</span>
        <MainNavLink 
          className='aaa-callus-menu__link'
          to={item.url}
          data-metrics-event-name='header_main_nav_click'
          data-metrics-label={item.text}
        >
          {item.text}
        </MainNavLink>
      </li>
    ))}
  </ul>
);

export class MainNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedSubmenu: null
    };
  }

  collapseSubmenu = () => {
    this.setState({ expandedSubmenu: null });
  }

  expandSubmenu = (i) => {
    if(this.props.items[i].submenu) {
      this.setState({ expandedSubmenu: i });
    }
  }

  render() {
    return (
      <nav className={'aaa-main-nav'}>
        {this.props.items.map( (item, i) => {
          return (
            <span 
              className={classnames('aaa-main-nav__item', {
                'has-popup': item.submenu && item.submenu.type === 'popup',
                'has-submenu': item.submenu,
                'spacer': item.spacer
              })}
              key={`mainnav_${i}`}
              onMouseEnter={ () => this.expandSubmenu(i) }
              onMouseLeave={ () => this.collapseSubmenu() }
            >
              { !item.spacer &&
                <MetricsElement>
                  <MainNavLink
                    to={item.url}
                    data-metrics-event-name='header_main_nav_click'
                    data-metrics-label={item.text}
                    target={item.newTab && '_blank'}
                  >
                    {item.text}
                  </MainNavLink>
                </MetricsElement>
        }
              { item.submenu && 
                <Fragment>
                  <span className='caret'>
                    <IconArrowDown />
                  </span>
                  {item.submenu.type == 'fullWidth' ? (
                    <FullWidthPopUp 
                      items={item.submenu.items} 
                      expanded={this.state.expandedSubmenu === i}
                    />
                  ) : (
                    <PopUp 
                      items={item.submenu.items}
                      expanded={this.state.expandedSubmenu === i}
                    />
                  )}
                </Fragment>
              }
            </span>
          );
        })}
      </nav>
    );
  }
}

export const MobileNavigation = ({ items, expanded, onSelect }) => (
  <nav className={classnames('aaa-mobile-nav', {'expanded': expanded,})}>
    <div className="aaa-mobile-nav__box">
      <ContentBox>
        <Accordion allowZeroExpanded={true} className="aaa-mobile-nav__items">
          {items.map((item, index) => (
            <AccordionItem
              className={classnames('aaa-mobile-nav__item', {'spacer': item.spacer})}
              collapsed="false"
              key={`mobilenav_${index}`}
              uuid={`mobilenav_${index}`}
            >
              <AccordionItemState>
                { ({ expanded }) => (
                  <Fragment>
                    <div className={`aaa-mobile-nav__item-box ${expanded ? 'expanded' : ''}`}>
                      <MetricsElement>
                        <MainNavLink 
                          className='aaa-mobile-nav__link'
                          to={item.url}
                          onClick={onSelect}
                          data-metrics-event-name='header_main_nav_click'
                          data-metrics-label={item.text}
                        >
                          { item.label && (
                            <span className='aaa-mobile-nav__title'>
                              {item.label}
                            </span> 
                          )}
                          {item.text}
                        </MainNavLink>
                      </MetricsElement>
                    </div>
                    { 
                      item.submenu &&
                      <Fragment>
                        <AccordionItemHeading>
                          <AccordionItemButton className="aaa-mobile-nav__caret-box">
                            <IconArrowDown className="aaa-mobile-nav__caret" />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AnimateHeight
                          duration={300}
                          height={expanded ? 'auto' : 0}
                        >
                          <div className="aaa-mobile-nav__subitems">
                            { item.submenu.items.map( (item, idx) => (
                              <div 
                                className={'aaa-mobile-nav__subitem'}
                                key={`mobilenav_${index}_${idx}`}
                              >
                                <MetricsElement>
                                  <MainNavLink 
                                    className="aaa-mobile-nav__link"
                                    to={item.url}
                                    target={item.newTab && '_blank'}
                                    onClick={onSelect}
                                    data-metrics-event-name='header_main_nav_click'
                                    data-metrics-label={item.text}
                                  >
                                    {item.text}
                                  </MainNavLink>
                                </MetricsElement>
                              </div>
                            ))}
                          </div>
                        </AnimateHeight>
                      </Fragment>
                    }
                  </Fragment>
                )}
              </AccordionItemState>
            </AccordionItem>
          ))}
        </Accordion>
      </ContentBox>
    </div>
  </nav>
);

const ProfileNavigationSource = () => (
  <UserIcon className='aaa-profile-menu__icon' />
);
export const ProfileNavigation = withSubmenu(ProfileNavigationSource, 'aaa-profile-menu', 'profile');
